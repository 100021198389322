var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.group)?_c('v-card',[_c('v-card-text',{staticClass:"pt-0"},[_c('div',{staticClass:"text-h4 py-6"},[_vm._v(" "+_vm._s(_vm.$t('tags.add_tag'))+" - "+_vm._s(_vm.group.group_name)+" ")]),_c('v-form',{on:{"submit":function($event){return _vm.submitTag($event, _vm.group.tags.length, _vm.group.id)}}},[_c('v-text-field',{directives:[{name:"prevent-keys",rawName:"v-prevent-keys"}],attrs:{"dense":"","solo":"","outlined":"","label":_vm.$t('tags.tag_name'),"placeholder":_vm.$t('tags.tag_name'),"autofocus":"","persistent-placeholder":true},model:{value:(_vm.tag_name),callback:function ($$v) {_vm.tag_name=$$v},expression:"tag_name"}}),_c('div',{staticStyle:{"display":"flex","justify-content":"left","align-items":"center"}},[_c('v-otp-input',{directives:[{name:"prevent-keys",rawName:"v-prevent-keys"}],key:_vm.tag_hotkey,staticStyle:{"max-width":"200px"},attrs:{"length":1,"dense":"","solo":"","outlined":"","label":_vm.$t('tags.tag_hotkey'),"placeholder":_vm.$t('tags.tag_hotkey'),"value":_vm.tag_hotkey,"persistent-placeholder":true},on:{"input":function($event){return _vm.handleHotkey($event)}}}),(_vm.tag_hotkey)?_c('v-btn',{staticStyle:{"display":"inline-block"},attrs:{"text":""},on:{"click":function($event){_vm.tag_hotkey = ''}}},[_c('v-icon',[_vm._v("mdi-close")])],1):_vm._e()],1),(!_vm.hotKeyValid)?_c('div',{staticClass:"ml-2 mb-2",staticStyle:{"color":"red"}},[_vm._v(_vm._s(_vm.$t('tags.hotkey_error')))]):_vm._e(),_c('div',{staticClass:"pt-0 my-5",staticStyle:{"display":"flex","align-items":"center","cursor":"pointer"},on:{"click":function($event){_vm.editingColor = true}}},[_c('div',{staticClass:"mr-4",staticStyle:{"display":"inline-block"}},[_vm._v(" "+_vm._s(_vm.$t("tags.tag_map_color"))+" ")]),_c('div',{style:({ 
            cursor: 'pointer', 
            height: '20px',
            width: '20px',
            backgroundColor: _vm.tag_map_color, 
            borderRadius: '100%',
            display: 'inline-block',
            border: !_vm.tag_map_color ? '1px solid lightgrey' : ''
          })}),(_vm.tag_map_color)?_c('div',{staticStyle:{"display":"inline-block"}},[_c('v-btn',{attrs:{"text":"","x-small":""},on:{"click":function($event){$event.stopPropagation();_vm.tag_map_color = null; _vm.editingColor = false;}}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1):_vm._e()]),(_vm.editingColor)?_c('v-color-picker',{staticClass:"mt-4",attrs:{"width":"100%","hide-sliders":"","hide-inputs":"","hide-canvas":"","elevation":"3","show-swatches":"","value":_vm.tag_map_color || '#eeeeee',"mode":'hex'},on:{"input":function($event){_vm.tag_map_color ? _vm.editingColor = false : ''; _vm.tag_map_color = $event}}}):_vm._e(),(_vm.group.action_type == 'metadata')?_c('div',[_c('v-switch',{attrs:{"label":_vm.$t('keep_chosen')},model:{value:(_vm.keep_chosen),callback:function ($$v) {_vm.keep_chosen=$$v},expression:"keep_chosen"}})],1):_vm._e(),_c('v-card-actions',[_c('v-spacer',[_c('v-btn',{staticClass:"mt-1",attrs:{"disabled":!_vm.tag_name || !_vm.hotKeyValid,"color":_vm.saved == _vm.group.id ? 'green' : 'primary',"type":"submit"}},[(_vm.saved != _vm.group.id)?_c('span',[_vm._v(_vm._s(_vm.$t('tags.add_tag'))+" (Enter)")]):_c('v-icon',{attrs:{"color":"white"}},[_vm._v("mdi-check")])],1)],1)],1)],1)],1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }