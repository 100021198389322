<template>
  <v-dialog max-width="1000" @click:outside="$emit('close')" :value="show">
    <v-card max-width="1000" v-if="show">
      <v-card-title>
        {{$t('settings.settings')}} - {{group.group_name}}
      </v-card-title>
      <v-card-text>
        <v-switch
          :label="$t('settings.show_in_filter')"
          :input-value="group.show_in_filtering"
          @change="updateGroupShowInFiltering({id: group.id, show_in_filtering: $event})"
        ></v-switch>
        <v-switch
          :label="$t('settings.shown_when_tagging')"
          :input-value="group.show_in_tagging"
          @change="updateGroupShowInTagging({id: group.id, show_in_tagging: $event})"
        ></v-switch>
        <v-card-subtitle v-if="group.mirrors.length">
          {{$t('tags.mirror_header')}}
        </v-card-subtitle>
        <v-select
          :value="group.mirrors.map(g => g.id)"
          :items="groupsAvailableForMirroring"
          @change="set($event)"
          attach
          dense
          x-small
          item-value="key"
          clearable
          solo
          chips
          :label="$t('tags.mirrors')"
          multiple
        ></v-select>
        <v-card-subtitle v-if="group.mirrors.length">
          Join
        </v-card-subtitle>
        <v-select
          :value="typeof group.show_in_join_w_group_tags == 'string' ? JSON.parse(group.show_in_join_w_group_tags) : group.show_in_join_w_group_tags"
          :items="tag_groups.map(group => { return {key: group.id, text: group.group_name} })"
          @change="setJoins($event)"
          attach
          dense
          multiple
          x-small
          item-value="key"
          clearable
          solo
          chips
          :label="$t('tags.show_in_join')"
        ></v-select>
        <v-switch
          :input-value="group.one_tag_only"
          :label="$t('tags.one_tag_only')"
          @change="updateGroupOneTagOnly({id: group.id, one_tag_only: $event})"
        ></v-switch>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
export default {
  props: ['group', 'show'],
  data: () => ({
    rules: [
      'if rule'
    ],
    addingRule: false
  }),
  created() {
    if(!this.tag_groups) {
      this.initTags().then()
    }
  },
  computed: {
    ...mapGetters('tag', [
      'tag_groups'
    ]),
    groupsAvailableForMirroring() {
      return this.tag_groups
        .filter(group => !group.mirrors || !group.mirrors.length)
        .map(group => { 
          return {key: group.id, text: group.group_name}
        })
    }
  },
  methods: {

    set(e) {
      if(e.length < 1) return
      this.updateGroupMirrors({
        id: this.group.id, 
        new_mirror_ids: e
      })
      .then(ee => {
        this.$emit('edited', ee)
      })
    },
    setJoins(e) {
      e = e.filter(i => typeof i == 'number')

      this.updateGroupJoins({
        id: this.group.id,
        join_ids: e
      })
      .then(ee => {
        this.$emit('edited', ee)
      })
    },
    ...mapActions('tag', [
      'updateGroupShowInFiltering',
      'updateGroupShowInTagging',
      'updateGroupOneTagOnly',
      'updateGroupMirrors',
      'updateGroupJoins',
      'initTags'
    ])
  }
}
</script>