<template>
  <div class="settings-row">
    <div class="settings-row-title text-h6">
      {{ title }}
      <v-tooltip v-if="explainer" top>
        {{ explainer }}
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            class="ml-5"           
            v-bind="attrs"
            v-on="on" 
            fab
            x-small
          >
            <v-icon>mdi-help</v-icon>
          </v-btn>
        </template>
      </v-tooltip>
    </div>
    <div class="settings-row-setting">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  props: ['title', 'explainer']
}
</script>

<style lang="scss">
.settings-row {
  display: flex;
  margin: 30px 0;

  &-title {
    flex-grow: 1;
    display: flex;
    align-items: center;
  }

  &-setting {
    display: flex;
    align-items: center;
    max-width: 500px;
  }
}
</style>