<template>
  <v-card class="pa-4" flat>
    <div class="text-h4 mb-5">
      {{ !editing_group ? $t('tags.add_tag_group') : $t('tags.edit_group_settings') }}
    </div>
    <div v-if="!editing_group" class="text-p mb-5">
      {{ $t('create_tag_group_info') }}
    </div>
    <v-text-field
      dense
      v-prevent-keys
      solo
      outlined
      :placeholder="$t('tags.tag_group_name')"
      autofocus
      :persistent-placeholder="true"
      v-model="group_name"
    />
    <div class="d-flex justify-center">
      <v-btn @click="show_advanced_options = !show_advanced_options" small rounded>
        <span v-if="!show_advanced_options">
          {{ $t('show_advanced_options') }}
        </span>
        <span v-else>
          {{ $t('hide_advanced_options') }}
        </span>
        <v-icon v-if="!show_advanced_options">mdi-chevron-down</v-icon>
        <v-icon v-else>mdi-chevron-up</v-icon>
      </v-btn>
    </div>
    <div class="mt-8" v-show="show_advanced_options">
      <!-- Ryhmän kopiointi toistaiseksi poistettu ominaisuus selkeyden vuoksi -->
      <!-- <settings-row :title="$t('tags.copied_group')" :explainer="$t('tags.clone_hint')">
        <v-switch
          :input-value="false"
          v-model="clone"
        />
      </settings-row> -->
      <!-- <settings-row :title="$t('tags.copied_groups')" v-if="clone">
        <v-select
          class="mt-4"
          v-model="mirrors"
          :items="groupsAvailableForMirroring"
          attach
          dense
          x-small
          item-value="key"
          clearable
          solo
          chips
          multiple
        ></v-select>
      </settings-row> -->
      <settings-row :title="$t('tag_group_type')" :explainer="$t('group_type_hint')">
        <v-select
          :items="action_type_values"
          hide-details
          attach
          v-model="action_type"
          solo
        >
          <template v-slot:item="{ item, attrs, on }">
            <v-list-item style="min-width: 550px;" two-line v-on="on" v-bind="attrs">
                <v-list-item-content>
                  <v-list-item-title>
                    {{ item.text }}
                  </v-list-item-title>
                  <v-list-item-subtitle>{{ item.subtext }}</v-list-item-subtitle>
                </v-list-item-content>
            </v-list-item>
          </template>
          <!-- <template v-slot:append-outer>
            <tell-more-button
              :title="$t('tag_group_type')"
              :info="$t('action_info')"
            ></tell-more-button>
          </template> -->
        </v-select>
      </settings-row>
      <v-divider></v-divider>
      <settings-row v-if="action_type == 'action'" :title="$t('stays_on')" :explainer="$t('enduring_hint')">
        <v-switch
          v-model="enduring"
        ></v-switch>
      </settings-row>
      <v-divider></v-divider>
      <settings-row v-if="action_type == 'action' && !enduring" :explainer="$t('range_selector_explain')" :title="$t('start_clip')">
        <RangeSelector :min="-1000" :max="0" v-model="buffer_start"></RangeSelector>
      </settings-row>
      <settings-row v-if="action_type == 'action' && !enduring" :explainer="$t('range_selector_explain')" :title="$t('end_clip')">
        <RangeSelector :min="1" :max="1000" v-model="buffer_end"></RangeSelector>
      </settings-row>
      <v-divider></v-divider>
      <settings-row :title="$t('settings.show_in_filter')">
        <v-switch
          v-model="show_in_filtering"
        >
        </v-switch>
      </settings-row>
      <settings-row :title="$t('tags.multiple')" :explainer="$t('tags.one_tag_only')">
        <v-switch
          @change="tag_group_one_only = !tag_group_one_only"
          :input-value="!tag_group_one_only"
        >
        </v-switch>
      </settings-row>
    </div>
    <v-card-actions>
      <v-btn @click="$emit('close')">{{ $t('cancel') }}</v-btn>
      <v-spacer></v-spacer>
      <v-btn color="primary" @click="saveClicked()">{{ $t('save') }}</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
// import TellMoreButton from '@/components/TellMoreButton.vue'
import RangeSelector from '@/components/tag/RangeSelector'
import SettingsRow from '@/components/settings/SettingsRow.vue'
import { mapActions, mapGetters } from 'vuex'

export default {
  props: ['editing_group', 'sport'],
  components: {
    // TellMoreButton, 
    RangeSelector, 
    SettingsRow 
  },
  data: () => ({
    group_name: '',
    action_type: "action",
    enduring: false,
    buffer_start: -2,
    buffer_end: 4,
    show_advanced_options: false,
    clone: false,
    tag_group_one_only: false,
    show_in_filtering: true
  }),
  created() {
    this.resetData()

    if(this.editing_group) {
      const g = this.editing_group
      this.show_advanced_options = true
      this.group_name = g.group_name
      this.action_type = g.action_type
      this.enduring = g.enduring
      this.buffer_start = g.buffer_start
      this.buffer_end = g.buffer_end
      this.tag_group_one_only = g.one_tag_only
      this.show_in_filtering = g.show_in_filtering
    }
  },
  methods: {
    ...mapActions('tag', [
      'addTagGroup',
      'updateTagGroup'
    ]),
    ...mapActions('noti', [
      'success',
      'error',
      'info'
    ]),
    submitTagGroup() {
        if(!this.group_name) return

        this.addTagGroup({
          group_name: this.group_name,
          team_id: this.currentTeamId,
          league_id: this.leagueId,
          sport_id: this.sportId,
          one_tag_only: this.tag_group_one_only,
          show_in_filtering: this.show_in_filtering,
          mirrors: false,
          position: 0,
          buffer_start: this.buffer_start,
          buffer_end: this.buffer_end,
          action_type: this.action_type,
          enduring: this.enduring,
          immutable: !!(this.sportId || this.leagueId)
        })
        .then(() => {
          this.resetData()
          this.$emit('close')
          this.success(this.$t('tags.tag_group_created'))
        })
        .catch(e => {
          console.log(e)
          this.error(e)
        })
        .finally(() => {
          this.saving_tag = null
        })
    },
    saveClicked() {
      if(this.editing_group) this.submitEditTagGroup()
      else this.submitTagGroup()
    },
    submitEditTagGroup() {
      if(!this.group_name) return

      this.updateTagGroup({
        group_name: this.group_name,
        action_type: this.action_type,
        enduring: this.enduring,
        buffer_start: this.buffer_start,
        buffer_end: this.buffer_end,
        tag_group_one_only: this.tag_group_one_only,
        show_in_filtering: this.show_in_filtering,
        id: this.editing_group.id
      })
      .then(() => {
        this.info(this.$t('tags.tag_group_updated'))
      })
      .catch(e => {
        this.error(e)
      })
      .finally(() => {
        this.$emit('close')
      })
    },
    resetData() {
      this.group_name = ''
      this.menu = null
      this.buffer_start = -2
      this.buffer_end = 5
      this.action_type = 'action'
      this.tag_group_one_only = false
      this.show_advanced_options = false
    }
  },
  computed: {
    ...mapGetters('tag', [
      'tag_groups'
    ]),
    ...mapGetters('user', [
      'currentTeamId'
    ]),
    sportId() {
      if(this.sport) return this.$route.params.sport_id
      return null
    },
    isAdmin() {
      return this.$route.path.includes('tiimi-admin')
    },
    leagueId() {
      if(!this.sport) return this.$route.params.league_id
      return null    
    },
    action_type_values() {
      return [
        { value: "action", text: this.$t('clips.clip'), subtext: this.$t('clips.clip_subtext') },
        { value: "metadata", text: this.$t('clips.metadata'), subtext: this.$t('clips.metadata_subtext') }
      ]
    },
    groupsAvailableForMirroring() {
      return this.tag_groups
        .filter(group => !group.mirrors || !group.mirrors.length)
        .map(group => { 
          return {key: group.id, text: group.group_name}
        })
    },
  }
}
</script>