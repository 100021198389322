<template>
  <v-card v-if="group">
    <v-card-text class="pt-0">
      <div class="text-h4 py-6">
        {{ $t('tags.add_tag') }} - {{group.group_name}}
      </div>
      <v-form @submit="submitTag($event, group.tags.length, group.id)">
        <v-text-field
          dense
          v-prevent-keys
          solo
          outlined
          :label="$t('tags.tag_name')"
          :placeholder="$t('tags.tag_name')"
          autofocus
          v-model="tag_name"
          :persistent-placeholder="true"
        >
        </v-text-field>
        <div style="display: flex; justify-content: left; align-items: center;">
          <v-otp-input
            style="max-width: 200px;"
            :length="1"
            dense
            v-prevent-keys
            solo
            :key="tag_hotkey"
            outlined
            :label="$t('tags.tag_hotkey')"
            :placeholder="$t('tags.tag_hotkey')"
            @input="handleHotkey($event)"
            :value="tag_hotkey"
            :persistent-placeholder="true"
          />
          <v-btn @click="tag_hotkey = ''" v-if="tag_hotkey" style="display: inline-block;" text>
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </div>
      <div class="ml-2 mb-2" v-if="!hotKeyValid" style="color: red;">{{$t('tags.hotkey_error') }}</div>
        <div class="pt-0 my-5" style="display: flex; align-items: center; cursor: pointer;" @click="editingColor = true" >
          <div style="display: inline-block;" class="mr-4">
            {{ $t("tags.tag_map_color")}}
          </div>
          <div 
            :style="{ 
              cursor: 'pointer', 
              height: '20px',
              width: '20px',
              backgroundColor: tag_map_color, 
              borderRadius: '100%',
              display: 'inline-block',
              border: !tag_map_color ? '1px solid lightgrey' : ''
            }"></div>
          <div v-if="tag_map_color" style="display: inline-block">
            <v-btn text x-small @click.stop="tag_map_color = null; editingColor = false;">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </div>
        </div>
        <v-color-picker 
          v-if="editingColor"
          width="100%"
          hide-sliders
          hide-inputs
          hide-canvas 
          elevation="3" 
          show-swatches
          :value="tag_map_color || '#eeeeee'"
          @input="tag_map_color ? editingColor = false : ''; tag_map_color = $event"
          class="mt-4"
          :mode="'hex'"
        ></v-color-picker>
        <div v-if="group.action_type == 'metadata'">
          <v-switch v-model="keep_chosen" :label="$t('keep_chosen')">

          </v-switch>
        </div>
        <v-card-actions>
          <v-spacer>
            <v-btn
              :disabled="!tag_name || !hotKeyValid"
              class="mt-1"
              :color="saved == group.id ? 'green' : 'primary'"
              type="submit"
            >
              <span v-if="saved != group.id">{{ $t('tags.add_tag') }} (Enter)</span>
              <v-icon v-else color="white">mdi-check</v-icon>
            </v-btn>
          </v-spacer>
        </v-card-actions>
      </v-form>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapActions } from 'vuex'
export default {
  props: ['group'],
  data: () => ({
    tag_name: '',
    tag_hotkey: '',
    editingColor: null,
    tag_map_color: null,
    saved: false,
    keep_chosen: false,
    isInputValid: true,
  }),
  methods: {
    ...mapActions('tag', [ 'addTag' ]),
    ...mapActions('noti', [ 'showSnackbar', 'success' ]),
    submitTag(e, position, group_id) {
      e.preventDefault();

      let self = this
      this.addTag({
        tag_name: this.tag_name,
        hotkey: this.tag_hotkey,
        add_to_sport: self.isAdmin,
        group_id,
        tag_map_color: this.tag_map_color,
        team_id: this.currentTeamId,
        position,
        keep_chosen: this.keep_chosen
      })
      .then(() => {
        this.tag_name = ''
        this.editingColor = false
        this.$emit('close')
        this.success(this.$t('tags.tag_added'))
        this.$emit('tag-added')
        setTimeout(() => { this.saved = null }, 1000)
      })
      .catch(err => {
        this.showSnackbar({
          text: err.response.data,
          type: 'error'
        })
      })
      .finally(() => {
        this.tag_name = ''
        this.tag_hotkey = ''
        this.editingColor = null
        this.tag_map_color = null
        this.saved = false
        this.keep_chosen = false
        this.isInputValid = true
      })
    },
    handleHotkey(e) {
      let char = e[0]
      if(!char || !char.length) return this.tag_hotkey = ''
      this.tag_hotkey = char.toLowerCase()
    },
  },
  computed: {
    isAdmin() {
      return this.$route.path.includes('tiimi-admin')
    },
    hotKeyValid() {
      if (this.tag_hotkey != this.tag_hotkey?.toLowerCase()) return false
      return true
    },
  }
}
</script>