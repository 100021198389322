<template>
  <div>
    <div>
      {{ title }}
    </div>
    <v-btn-toggle dense>
      <v-btn small @click="value > min ? $emit('input', value - 1) : ''">
        <v-icon>mdi-minus</v-icon>
      </v-btn>
      <v-btn small>
        {{ value }} s
      </v-btn>
      <v-btn small @click="value < max ? $emit('input', value + 1): ''">
        <v-icon>mdi-plus</v-icon>
      </v-btn>
    </v-btn-toggle>
  </div>
</template>

<script>
export default {
  props: ['value', 'title', 'max', 'min']
}
</script>